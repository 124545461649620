<template>
  <div class="newAll">
    <el-container>
      <el-header style="height: 0px; padding: 0px"> </el-header>
      <el-main>
        <el-backtop :visibility-height="400" :bottom="100"></el-backtop>
        <div class="alls">
          <div class="alls">
            <div class="newFirst" v-show="ShowNavs1">
              <div class="topNav">
                <div class="topNav1">
                  <div class="topNav1-1">
                    <img
                      src="../../../assets/img/logo/logo.png"
                      alt=""
                      @click="jumpIndex"
                    />
                  </div>
                  <div class="topNav1-2">
                    <img
                      src="../../../assets/img/mb/indexs/menu.png"
                      alt=""
                      @click="ShowNav"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="newFirst1" v-show="ShowNavs">
              <div class="topNav">
                <div class="topNav1">
                  <div class="topNav1-1">
                    <img
                      src="../../../assets/img/logo/logo.png"
                      alt=""
                      @click="jumpIndex"
                    />
                  </div>
                  <div class="topNav1-2">
                    <img
                      src="../../../assets/img/mb/indexs/menu.png"
                      alt=""
                      @click="ShowNav1"
                    />
                  </div>
                </div>
              </div>
              <div class="firstMiddle">
                <el-menu
                  ref="elmenudemo"
                  :default-active="$route.path"
                  :router="true"
                  @open="handleOpen"
                  @close="handleClose"
                  class="el-menu-vertical-demo"
                  @select="handleSelect"
                  background-color="#F0F0F0"
                  text-color="#000"
                  active-text-color="#ffd04b"
                >
                  <el-menu-item index="/mb/index"> 首页</el-menu-item>
                  <el-submenu index="2">
                    <template
                      slot="title"
                      style="width: 220px; font-size: 0.9rem"
                      >产品中心</template
                    >
                    <el-menu-item index="/mb/mappingCenter" style="width: 220px"
                      >御之安网络资产测绘平台</el-menu-item
                    >
                    <el-menu-item index="/mb/ctfCenter" style="width: 220px"
                      >御之安CTF竞赛平台</el-menu-item
                    >
                    <el-menu-item index="/mb/redBlueCenter" style="width: 220px"
                      >御之安红蓝对抗平台</el-menu-item
                    >
                    <el-menu-item
                      index="/mb/vulnerabilityCenter"
                      style="width: 220px"
                      >御之安科技漏洞管理平台</el-menu-item
                    >
                  </el-submenu>
                  <el-submenu index="3">
                    <template slot="title">安全服务</template>
                    <el-menu-item
                      index="/mb/fencingOperations"
                      style="padding-left: 20px"
                    >
                      护网行动</el-menu-item
                    >
                    <el-menu-item
                      index="/mb/safetyCompetition"
                      style="padding-left: 20px"
                    >
                      安全竞赛</el-menu-item
                    >
                    <el-menu-item
                      index="/mb/codeAudit"
                      style="padding-left: 20px"
                      >代码审计</el-menu-item
                    >
                    <el-menu-item
                      index="/mb/emergencyResponse"
                      style="padding-left: 20px"
                      >应急响应</el-menu-item
                    >
                    <el-menu-item
                      index="/mb/securityOperation"
                      style="padding-left: 20px"
                      >安全运维</el-menu-item
                    >
                    <el-menu-item
                      index="/mb/securityReinforcement"
                      style="padding-left: 20px"
                      >安全加固</el-menu-item
                    >
                    <el-menu-item
                      index="/mb/securityAdvisory"
                      style="padding-left: 20px"
                      >安全咨询</el-menu-item
                    >
                  </el-submenu>
                  <el-submenu index="4">
                    <template slot="title">赛事服务</template>
                    <el-menu-item index="4-1" style="padding-left: 20px"
                      >比赛入口</el-menu-item
                    >
                    <el-menu-item index="4-2" style="padding-left: 20px"
                      >赛事承办</el-menu-item
                    >
                    <el-menu-item index="4-2" style="padding-left: 20px"
                      >赛事复盘</el-menu-item
                    >
                  </el-submenu>
                  <el-submenu index="5">
                    <template slot="title">校企合作</template>
                    <el-menu-item
                      index="/mb/safetyTraining"
                      style="padding-left: 20px"
                      >安全培训</el-menu-item
                    >
                    <el-menu-item
                      index="/mb/platformEquipment"
                      style="padding-left: 20px"
                      >平台设备</el-menu-item
                    >
                  </el-submenu>
                  <el-submenu index="6">
                    <template slot="title">关于御之安</template>
                    <el-menu-item
                      index="/mb/companyProfile"
                      style="padding-left: 20px"
                      >公司介绍</el-menu-item
                    >
                    <el-menu-item index="/mb/joinUs" style="padding-left: 20px"
                      >加入我们</el-menu-item
                    >
                    <el-menu-item
                      index="/mb/contactUs"
                      style="padding-left: 20px"
                      >联系我们</el-menu-item
                    >
                  </el-submenu>
                </el-menu>
              </div>
            </div>
          </div>
          <el-dialog
            :close-on-click-modal="false"
            style="text-align: left"
            :visible.sync="popup"
            width="30%"
          >
            <div class="form-layer">
              <el-form label-width="100px" size="mini">
                <el-form-item label="获取定位">
                  <el-button type="primary" @click="fixedPos"
                    >重新定位</el-button
                  >
                </el-form-item>
                <el-form-item label="当前纬度">
                  <p>{{ latitude }}</p>
                </el-form-item>
                <el-form-item label="当前经度">
                  <p>{{ longitude }}</p>
                </el-form-item>
                <el-form-item>
                  <div class="f-a-c">
                    <el-input
                      v-model="keyWords"
                      placeholder="请输入地区"
                      style="width: 230px; margin-right: 6px"
                    ></el-input>
                    <el-button
                      type="primary"
                      @click="setPlace"
                      :disabled="!keyWords"
                      >查询</el-button
                    >
                  </div>
                </el-form-item>
              </el-form>
              <div id="map"></div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button size="small" type="primary" @click="btnSubmit()"
                >确 认</el-button
              >
              <el-button size="small" @click="popup = false">取 消</el-button>
            </div>
          </el-dialog>
        </div>
        <keep-alive>
          <router-view />
        </keep-alive>
      </el-main>
      <el-footer style="height: 0px; margin-top: auto">
        <div class="lasts">
          <div class="lastsTop">
            <h3><router-link to="/mb/index">御之安</router-link></h3>
            <div class="lastsTop1">
              <p>地址:中国(四川)成都武侯区天府三街新希望国际大厦B2座2216A</p>
              <p>邮箱:admin@yuzhian.com.cn</p>
              <p>电话:+86 18381475890,+86 18770382226</p>
            </div>
            <div class="lastsTop3-1">
              <div class="lastsTop2">
                <p>赛事服务:</p>
                <p>比赛入口</p>
                <p>赛事承办</p>
                <p>赛事复盘</p>
              </div>
              <div class="lastsTop2">
                <p>校企合作:</p>
                <p>
                  <router-link to="/mb/safetyTraining">安全培训</router-link>
                </p>
                <p>
                  <router-link to="/mb/platformEquipment">平台设备</router-link>
                </p>
              </div>
              <div class="lastsTop2">
                <p>关于御之安:</p>
                <p>
                  <router-link to="/mb/companyProfile">关于我们</router-link>
                </p>
                <p><router-link to="/mb/joinUs">加入我们</router-link></p>
                <p><router-link to="/mb/contactUs">联系我们</router-link></p>
              </div>
              <div class="lastsTop2">
                <p>安全服务:</p>
                <p>
                  <router-link to="/mb/safetyTraining">安全培训</router-link>
                </p>
                <p><router-link to="/mb/codeAudit">代码审计</router-link></p>
                <p>
                  <router-link to="/mb/emergencyResponse">应急响应</router-link>
                </p>
                <p>
                  <router-link to="/mb/safetyCompetition">安全竞赛</router-link>
                </p>
                <p>
                  <router-link to="/mb/securityReinforcement"
                    >安全加固</router-link
                  >
                </p>
                <p>
                  <router-link to="/mb/securityAdvisory">安全咨询</router-link>
                </p>
              </div>
              <div class="lastsTop3">
                <p>产品中心:</p>
                <p>
                  <router-link to="/mb/mappingCenter"
                    >御之安网络资产测绘平台</router-link
                  >
                </p>
                <p>
                  <router-link to="/mb/ctfCenter"
                    >御之安CTF竞赛平台</router-link
                  >
                </p>
                <p>
                  <router-link to="/mb/redBlueCenter"
                    >御之安红蓝对抗平台</router-link
                  >
                </p>
                <p>
                  <router-link to="/mb/vulnerabilityCenter"
                    >御之安科技漏洞管理平台</router-link
                  >
                </p>
              </div>
            </div>
            <div class="lastsTop6">
              <div class="lastsTop6-1">
                <p>企业微信:</p>
                <img src="../../../assets/img/logo/wechat.jpg" alt="" />
              </div>
              <div class="lastsTop6-2">
                <p>公众号:</p>
                <img src="../../../assets/img/logo/wechat1.jpg" alt="" />
              </div>
            </div>
          </div>
          <div class="lastsTop7">
            <ul>
              <li>
                <router-link to="/mb/legalStatement">法律声明</router-link>
              </li>
              <li>
                <router-link to="/mb/intellectualPropertyDescription"
                  >知识产权声明</router-link
                >
              </li>
              <li>
                <router-link to="/mb/privacyProtection">隐私保护</router-link>
              </li>
            </ul>
          </div>
          <div class="lastsMiddle">
            <router-link to="/mb/index"
              >@2021成都御之安科技有限公司</router-link
            >
            &nbsp;&nbsp;<a href="http://beian.miit.gov.cn/"
              >蜀ICP备2022013491号-1</a
            >&nbsp;&nbsp;&nbsp;&nbsp;
            <span @click="openDialog"
              >地址:中国(四川)自由贸易试验区成都高新区新希望国际B2座2216A</span
            >
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      isCollapse: false,
      activeName: "first",
      activeIndex2: "",
      wechatShow: false,
      showNav: true,
      map: null,
      local: null,
      mk: null,
      latitude: "",
      longitude: "",
      keyWords: "新希望国际B座",
      popup: false,
      classOption: {
        limitMoveNum: 2,
        direction: 3,
      },
      classOption1: {
        limitMoveNum: 2,
        direction: 2,
      },
      indexShow: true,
      keys: "", //为了首页
      ShowNavs: false,
      ShowNavs1: true,
    };
  },

  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollLength, false);
    // this.$nextTick(() => {
    //   // console.log(this.$refs.elmenudemo);
    //   // if (this.$refs.elmenudemo[0]) {
    //   //   this.$refs.elmenudemo[0].$el.click();
    //   // }
    //   this.$emit('handleSelect', this.keys)
    // });
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.scrollLength);
  },
  methods: {
    ShowNav() {
      this.ShowNavs = true;
      this.ShowNavs1 = false;
    },
    ShowNav1() {
      this.ShowNavs = false;
      this.ShowNavs1 = true;
    },
    jumpIndex() {
      this.$router.push("/mb/index");
    },
    scrollLength() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const top = document.documentElement.scrollTop;
      if (top > 80.55) {
        let opacity = top / 100;
        opacity = opacity > 1 ? 1 : opacity;
        this.showOpacity = { opacity };
        this.showNav = false;
      }
      if (top < 50) {
        this.showNav = true;
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.keys = "/pc/indexs";
      this.ShowNavs = false;
      this.ShowNavs1 = true;
      // if (this.keys !== "/") {
      //   this.indexShow = false;
      // }
      // if (this.keys == "/") {
      //   this.indexShow = true;
      // }
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    // 打开弹窗，name为弹窗名称
    async openDialog() {
      this.popup = true;
      this.initMap();
    },
    // 确认
    btnSubmit() {
      let key = {
        latitude: this.latitude,
        longitude: this.longitude,
      };
      // 打印经纬度
      console.log(key);
      this.popup = false;
    },
    initMap() {
      this.$nextTick(() => {
        this.map = new window.BMap.Map("map");
        let point = new window.BMap.Point(30.552246, 104.072747);
        this.map.centerAndZoom(point, 12);
        this.map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
        this.map.addControl(new window.BMap.NavigationControl());
        this.fixedPos();
      });
    },
    // 点击定位-定位到当前位置
    fixedPos() {
      const _this = this;
      const geolocation = new window.BMap.Geolocation();
      this.confirmLoading = true;
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          _this.handleMarker(_this, r.point);
          let myGeo = new window.BMap.Geocoder();
          myGeo.getLocation(
            new window.BMap.Point(r.point.lng, r.point.lat),
            function (result) {
              _this.confirmLoading = false;
              if (result) {
                _this.latitude = result.point.lat;
                _this.longitude = result.point.lng;
              }
            }
          );
        } else {
          _this.$message.error("failed" + this.getStatus());
        }
      });
    },
    // 搜索地址
    setPlace() {
      this.local = new window.BMap.LocalSearch(this.map, {
        onSearchComplete: this.searchPlace,
      });
      this.local.search(this.keyWords);
    },
    searchPlace() {
      if (this.local.getResults() != undefined) {
        this.map.clearOverlays(); //清除地图上所有覆盖物
        if (this.local.getResults().getPoi(0)) {
          let point = this.local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
          this.map.centerAndZoom(point, 18);
          this.handleMarker(this, point);
          console.log("经度：" + point.lng + "--" + "纬度" + point.lat);
          this.latitude = point.lat;
          this.longitude = point.lng;
        } else {
          this.$message.error("未匹配到地点!");
        }
      } else {
        this.$message.error("未找到搜索结果!");
      }
    },
    // 设置标注
    handleMarker(obj, point) {
      let that = this;
      obj.mk = new window.BMap.Marker(point);
      obj.map.addOverlay(obj.mk);
      obj.mk.enableDragging(); // 可拖拽
      obj.mk.addEventListener("dragend", function (e) {
        // 监听标注的拖拽，获取拖拽后的经纬度
        that.latitude = e.point.lat;
        that.longitude = e.point.lng;
      });
      obj.map.panTo(point);
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  /*其他*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/deep/.el-backtop {
  width: 60px !important;
  height: 60px !important;
  font-size: 2rem !important;
  z-index: 88;
}
/deep/.el-main {
  padding: 0px !important;
}
.newAll {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .alls {
    width: 100%;
    @media (max-width: 750px) {
      .newFirst {
        overflow: auto;
        width: 100%;
        position: fixed;
        background-color: #f0f0f0;
        left: 0;
        top: 0;
      }
    }
    @media (max-width: 1100px) {
      .newFirst {
        overflow: auto;
        width: 98%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .newFirst {
      width: 100%;
      // 移动端导航
      .topNav {
        position: fixed;
        z-index: 99;
        width: 100%;
        top: 0px;
        left: 0px;
        background-color: #f0f0f0;
        box-shadow: 0 2px 2px 0 rgb(233, 230, 230);
        transition: all 0.5s;
        .topNav1 {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 10px 40px;
          .topNav1-1 {
            img {
              width: 35px;
              height: 35px;
            }
          }
          .topNav1-2 {
            img {
              width: 35px;
              height: 35px;
            }
          }
        }
      }
      //   .firstLeft {
      //     width: 10%;
      //     img {
      //       width: 55px;
      //       height: 55px;
      //     }
      //   }
      //   .firstMiddle {
      //     font-size: 1rem !important;
      //     // margin-left: 300px;
      //     // background-color: #484a4e;
      //     /deep/#newElMenu {
      //       width: 220px !important;
      //     }
      //     /deep/.el-menu-item {
      //       // font-size: 1.25rem;
      //     }
      //     /deep/.el-submenu__title {
      //       padding: 0 20px !important;
      //       // font-size: 1.25rem;
      //     }
      //     /deep/.el-menu
      //       .el-menu--popup
      //       .el-menu--popup-bottom-start
      //       .el-menu-item {
      //       font-size: 1.5rem !important;
      //     }
      //   }
    }
    @media (max-width: 750px) {
      .newFirst1 {
        overflow: auto;
        width: 98%;
        height: 100vh;
        position: fixed;
        background-color: #f0f0f0;
        left: 0;
        top: 0;
      }
    }

    .newFirst1 {
      width: 100%;
      overflow: auto;
      width: 100%;
      height: 100vh;
      position: fixed;
      z-index: 999;
      left: 0;
      top: 0;
      background-color: #f0f0f0;
      box-shadow: 0 2px 2px 0 rgb(233, 230, 230);
      transition: all 0.5s;
      // 移动端导航
      .topNav {
        .topNav1 {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 10px 40px;
          .topNav1-1 {
            img {
              width: 55px;
              height: 55px;
            }
          }
          .topNav1-2 {
            img {
              width: 35px;
              height: 35px;
            }
          }
        }
      }
      //   .firstLeft {
      //     width: 10%;
      //     img {
      //       width: 55px;
      //       height: 55px;
      //     }
      //   }
      .firstMiddle {
        padding: 10px 40px;
        //     font-size: 1rem !important;
        //     // margin-left: 300px;
        //     // background-color: #484a4e;
        //     /deep/#newElMenu {
        //       width: 220px !important;
        //     }
        //     /deep/.el-menu-item {
        //       // font-size: 1.25rem;
        //     }
        //     /deep/.el-submenu__title {
        //       padding: 0 20px !important;
        //       // font-size: 1.25rem;
        //     }
        //     /deep/.el-menu
        //       .el-menu--popup
        //       .el-menu--popup-bottom-start
        //       .el-menu-item {
        //       font-size: 1.5rem !important;
        //     }
      }
    }
    .form-layer {
      width: 100%;
    }
    #map {
      margin-top: 30px;
      width: 100%;
      height: 300px;
      border: 1px solid gray;
      box-sizing: border-box;
      overflow: hidden;
    }
    /deep/ .el-dialog {
      min-width: 550px;
    }
    /deep/ .el-dialog__body {
      padding: 10px;
    }
  }
  .container {
    flex: 1;
  }
  /deep/.el-footer {
    padding: 0px !important;
  }
  .lasts {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #484a4e;
    padding: 10px 10px;
    box-shadow: 2px 2px 10px #515151;
    padding-top: 50px;
    a {
      color: #ffffff;
    }
    .lastsTop {
      width: 100%;
      display: flex;
      padding-bottom: 30px;
      flex-direction: column;
      justify-content: space-around;
      h3 {
        font-size: 2rem;
        color: #fff;
        text-align: center;
      }
      .lastsTop1 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 30px;
        padding: 0 20px;
        p {
          margin-top: 5px;
          font-size: 1rem;
          color: #ffffff;
        }
      }
      .lastsTop3-1 {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
      }
      .lastsTop3 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 20px;
        margin-top: 20px;
        p {
          margin-top: 5px;
          font-size: 1rem;
          color: #ffffff;
        }
      }
      .lastsTop2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 20px;
        margin-top: 20px;
        p {
          margin-top: 5px;
          font-size: 1rem;
          color: #ffffff;
        }
      }
      .lastsTop6 {
        display: flex;
        justify-content: space-around;
        margin-left: 20px;
        margin-top: 20px;
        .lastsTop6-2 {
          margin-left: 30px;
        }
        p {
          font-size: 1rem;
          color: #ffffff;
        }

        img {
          width: 5rem;
          height: 5rem;
          margin-top: 5px;
        }
      }
    }
    .lastsTop7 {
      margin-left: 20px;
      margin-top: 20px;
      ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 0.7rem;
        margin-top: 10px;
        padding-bottom: 10px;
        margin-right: 10px;
        li {
          padding: 0 5px;
          border-right: 1px solid #ccc;
        }
      }
    }

    .lastsMiddle {
      color: #fff;
      border-top: 3px solid #79797a;
      padding-top: 8px;
      text-align: center;
      font-size: 12px;
      a {
        color: #fff;
      }
      span {
        cursor: pointer;
      }
    }
  }
}
</style>